const firebaseConfig = {
  apiKey: "AIzaSyB44d7dbK57wUEr2De0ELWhqUNj9dhgzgE",
  authDomain: "jadeja-art.firebaseapp.com",
  databaseURL: "https://jadeja-art.firebaseio.com",
  projectId: "jadeja-art",
  storageBucket: "jadeja-art.appspot.com",
  messagingSenderId: "178607662142",
  appId: "1:178607662142:web:ece6b3f56ccc70b7691d6f",
  measurementId: "G-LYHQ33Y006",
};

export default firebaseConfig;
