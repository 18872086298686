import React, { useState } from "react";
import Header from "./components/Header";
import { Switch, Route } from "react-router-dom";
import { firestore } from "./firebase/utils";
import "./App.css";
import Art from "./components/ArtPage";

const App = () => {
  const [art, setArt] = useState([]);

  React.useEffect(() => {
    firestore
      .collection("art")
      .get()
      .then((data) => {
        let artToPush = [];
        data.forEach((doc) => {
          artToPush.push(doc.data());
        });
        setArt(artToPush);
      });
  }, []);

  return (
    <div className="App">
      <Header />
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <div style={{ marginTop: "1rem" }} id="page">
              <h1 style={{ textAlign: "center", fontSize: "9rem" }}>
                Jadeja Art
              </h1>
              <ul
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  listStyle: "none",
                }}
              >
                {art.map((data) => (
                  <div>
                    <li
                      style={{
                        textAlign: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          color: "yellow",
                          textAlign: "center",
                        }}
                        href={data.image}
                      >
                        <h1>{data.name}</h1>
                      </a>
                    </li>
                    {/* <img
                      src={data.image}
                      width={200}
                      style={
                        {
                          // border: "7px solid black",
                        }
                      }
                    /> */}
                  </div>
                ))}
              </ul>
            </div>
          )}
        />
        <Route to="/art" render={() => <Art art={art} />} />
        <Route
          render={() => (
            <div style={{ textAlign: "center", marginTop: "2rem" }} id="page">
              <h3>Error 404. Page Not Found</h3>
            </div>
          )}
        />
      </Switch>
    </div>
  );
};

export default App;
