import React, { useState } from "react";
import { Button } from "@material-ui/core";
import "./ArtPage.css";

const Art = (props) => {
  const art = props.art;
  const [section, setSection] = useState("all");
  const [showNav, setShowNav] = useState(true);

  const navClicked = (e) => {
    setSection(e.target.innerHTML.toLowerCase());
    console.log(section);
    setShowNav(false);
  };

  React.useEffect(() => {
    console.log(art.length);
  });

  return (
    <div
      style={{
        marginTop: "1rem",
      }}
      id="page"
    >
      {showNav && (
        <span
          style={{
            textAlign: "center",
            fontWeight: "bolder",
            fontSize: "1.5rem",
            color: "blue",
            cursor: "pointer",
            maxWidth: "9rem",
            maxHeight: "8rem",
          }}
        >
          <p onClick={navClicked}>Avnish</p>
          <p onClick={navClicked}>Mandala Series</p>
          <p onClick={navClicked}>Non-attachment Series</p>
          <p onClick={navClicked}>Syrian Migration</p>
          <p onClick={navClicked}>Requiem for George Floyd</p>
          <p onClick={navClicked}>Elegy to New York, New York</p>
          <p onClick={navClicked}>Foggy Morning Series</p>
          <p onClick={navClicked}>New Horizons</p>
          <p onClick={navClicked}>On The Top</p>
          <p onClick={navClicked}>Baba Nana Series</p>
          <p onClick={navClicked}>Personal Favorites</p>
          <p onClick={navClicked}>All</p>
          <main
            style={{
              marginTop: 80,
            }}
          />
          {/* <p>
            ---------------------------------------------------------------------------------------------------------------------------------------
          </p> */}
        </span>
      )}
      {section === "all" && (
        <ul
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            listStyle: "none",
          }}
        >
          {art.map((data, key) => {
            let style = { border: "7px solid black" };
            // console.log(widths);
            return (
              <div>
                <li
                  style={{
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none",
                      color: "black",
                      textAlign: "center",
                    }}
                    href={data.image}
                  >
                    <h1>{data.name}</h1>
                  </a>
                </li>
                <img
                  src={data.image}
                  width={200}
                  style={style}
                  alt={data.name}
                  onClick={(e) => {
                    if (e.target.state == "small") {
                      e.target.style.transform = "scale(2)";
                      e.target.state = "big";
                    } else {
                      e.target.style.transform = "scale(1)";
                      e.target.state = "small";
                    }
                  }}
                  state={"small"}
                />
              </div>
            );
          })}
        </ul>
      )}
      {section !== "all" && (
        <ul
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            listStyle: "none",
          }}
        >
          {art.map((data) => (
            <div>
              {data.section === section && (
                <li
                  style={{
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none",
                      color: "black",
                      textAlign: "center",
                    }}
                    href={data.image}
                  >
                    <h1>{data.name}</h1>
                  </a>
                </li>
              )}
              {data.section === section && (
                <img
                  src={data.image}
                  width={200}
                  style={{
                    border: "7px solid black",
                  }}
                  alt={data.name}
                  onClick={(e) => {
                    if (e.target.state == "small") {
                      e.target.style.transform = "scale(3)";
                      e.target.state = "big";
                    } else {
                      e.target.style.transform = "scale(1)";
                      e.target.state = "small";
                    }
                  }}
                  state={"small"}
                />
              )}
            </div>
          ))}
        </ul>
      )}
      {!showNav && (
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: window.innerWidth / 2 - 20,
          }}
          onClick={() => {
            setSection("all");
            setShowNav(true);
          }}
        >
          Go Back
        </Button>
      )}
    </div>
  );
};

export default Art;
