import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormGroup from "@material-ui/core/FormGroup";
// import MenuItem from "@material-ui/core/MenuItem";
// import Menu from "@material-ui/core/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const [links, setLinks] = React.useState(false);
  const classes = useStyles();
  //   const [auth, setAuth] = React.useState(true);
  //   const [anchorEl, setAnchorEl] = React.useState(null);
  //   const open = Boolean(anchorEl);

  //   const handleChange = (event) => {
  //     setAuth(event.target.checked);
  //   };

  //   const handleMenu = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  return (
    <div className={classes.root}>
      {/* <FormGroup>
        <FormControlLabel
          control={<Switch checked={auth} onChange={handleChange} aria-label="login switch" />}
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup> */}
      <AppBar
        position="static"
        style={{
          zIndex: 10000,
        }}
      >
        <Toolbar>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >
              <b>Jadeja Art</b>
            </Link>
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button href="mailto:jaswant.jadeja@gmail.com">Contact Us</Button>
            {links === false ? (
              <a
                // to="/art"
                // style={{
                //   color: "white",
                //   textDecoration: "none",
                //   marginLeft: "1rem",
                // }}
                className="link"
                onMouseOver={() =>
                  //   (document.getElementById("page").innerHTML = `
                  //   <div style="text-align: center">
                  //     <h2><a style="color: black; text-decoration; none" href="/art" onClick="(e) => e.preventDefault()">Art</a></h2>
                  //     <h2><a style="color: black; text-decoration; none" href="/" onClick="(e) => e.preventDefault()">Home</a></h2>
                  //   </div>
                  // `)
                  {
                    setLinks(true);
                  }
                }
              >
                Hover Over Me!
              </a>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Link
                  to="/art"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginLeft: ".3rem",
                  }}
                >
                  Art
                </Link>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginLeft: ".5rem",
                  }}
                >
                  Home
                </Link>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
